import { createReducer } from '@reduxjs/toolkit';
import nookies from 'nookies';
import {
  getPlanInfoAction,
  getStripeV2ProductsAction,
} from '../actions/paymentActions';

export type planInfoState = {
  planInfoData: {};
  planInfoPending: boolean;
  planInfoError: boolean;
  planInfoSuccess: boolean;
  planInfoErrorData: {};
};

const planInfoInitialState: planInfoState = {
  planInfoData: {},
  planInfoPending: false,
  planInfoError: false,
  planInfoSuccess: false,
  planInfoErrorData: {},
};

export const getPlanInfoReducer = createReducer(
  planInfoInitialState,
  (builder) => {
    builder
      .addCase(getPlanInfoAction.pending, (state) => {
        state.planInfoPending = true;
      })
      .addCase(getPlanInfoAction.fulfilled, (state, { payload }) => {
        state.planInfoPending = false;
        state.planInfoData = payload;
        state.planInfoSuccess = true;
        nookies.set(
          null,
          'hasActiveSubscription',
          payload.has_active_subscription,
          {
            path: '/',
          }
        );
        nookies.set(null, 'isUserOnTrial', payload.is_user_on_trail, {
          path: '/',
        });
      })
      .addCase(getPlanInfoAction.rejected, (state, { payload }) => {
        state.planInfoPending = false;
        state.planInfoError = true;
        state.planInfoErrorData = payload;
        state.planInfoSuccess = false;
      });
  }
);

export type stripeProductState = {
  stripeProductData: [];
  stripeProductPending: boolean;
  stripeProductError: boolean;
  stripeProductSuccess: boolean;
  stripeProductErrorData: {};
};

const stripeProductInitialState: stripeProductState = {
  stripeProductData: [],
  stripeProductPending: false,
  stripeProductError: false,
  stripeProductSuccess: false,
  stripeProductErrorData: {},
};

export const getStripeProductsReducer = createReducer(
  stripeProductInitialState,
  (builder) => {
    builder
      .addCase(getStripeV2ProductsAction.pending, (state) => {
        state.stripeProductPending = true;
      })
      .addCase(getStripeV2ProductsAction.fulfilled, (state, { payload }) => {
        state.stripeProductPending = false;
        state.stripeProductData = payload;
        state.stripeProductSuccess = true;
      })
      .addCase(getStripeV2ProductsAction.rejected, (state, { payload }) => {
        state.stripeProductPending = false;
        state.stripeProductError = true;
        state.stripeProductErrorData = payload;
        state.stripeProductSuccess = false;
      });
  }
);
