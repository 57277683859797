import apiRoutes from '../shared/api/apiRoutes';

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const formatPercentage = (value: number): string => {
  return `${value.toFixed(1)}%`;
};

export const convertMoneyToHumanReadable = (value: number): number => {
  return value / 100;
};

export const convertMoneyToGateway = (value: number): number => {
  return value * 100;
};

export const checkForCustomUrl = (url: string, baseUrl: string): boolean => {
  const actualUrl = url.replace(baseUrl, '');
  if (actualUrl === apiRoutes.getCustomToken) {
    return true;
  } else {
    return false;
  }
};

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function getEventTypes(eventName: string) {
  const eventTypes: any = {
    INIT: 'INIT',
    PAGINATION: 'PAGINATION',
    APPLYING: 'APPLYING',
    COMPLETE: 'COMPLETE',
  };
  return eventTypes[eventName];
}

export const jobStages: {
  [key: number]: string;
} = {
  1: 'applied',
  2: 'skipped',
  3: 'hrContacted',
  4: 'hrSelected',
  5: 'firstInterview',
  6: 'secondInterview',
  7: 'selected',
  8: 'rejected',
};

const isClientSide = (): boolean => typeof window !== 'undefined';

export default isClientSide;
