import { createReducer } from '@reduxjs/toolkit';
import { IUserWithRole } from '../../shared/models/IUserManagement';
import {
  addUserAction,
  deleteUserAction,
  getIdTokenResultAction,
  getUsersAction,
  inviteUserCheckingAction,
  resetUserAction,
  updateUserPasswordAction,
} from '../actions';

export type AddUserState = {
  addUserData: {};
  addUserPending: boolean;
  addUserError: boolean;
  addUserErrorPayload: {};
  addUserSuccess: boolean;
};

const initialState: AddUserState = {
  addUserData: {},
  addUserPending: false,
  addUserError: false,
  addUserErrorPayload: {},
  addUserSuccess: false,
};

export const addUserReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addUserAction.pending, (state) => {
      state.addUserPending = true;
    })
    .addCase(addUserAction.fulfilled, (state, { payload }) => {
      state.addUserPending = false;
      state.addUserData = payload;
      state.addUserSuccess = true;
    })
    .addCase(addUserAction.rejected, (state, { payload }) => {
      state.addUserPending = false;
      state.addUserError = true;
      state.addUserErrorPayload = payload;
    })
    .addCase(resetUserAction.fulfilled, (state, { payload }) => {
      state.addUserSuccess = false;
    });
});

export default addUserReducer;

export type GetUsersState = {
  getUsersData: IUserWithRole[];
  getUsersPending: boolean;
  getUsersError: boolean;
  getUserErrorPayload: {};
};

const getUserInitialState: GetUsersState = {
  getUsersData: [],
  getUsersPending: false,
  getUsersError: false,
  getUserErrorPayload: {},
};

export const getUsersReducer = createReducer(getUserInitialState, (builder) => {
  builder
    .addCase(getUsersAction.pending, (state) => {
      state.getUsersPending = true;
    })
    .addCase(getUsersAction.fulfilled, (state, { payload }) => {
      state.getUsersPending = false;
      state.getUsersData = payload;
    })
    .addCase(getUsersAction.rejected, (state, { payload }) => {
      state.getUsersPending = false;
      state.getUsersError = true;
      state.getUserErrorPayload = payload;
    });
});

export type InviteUserCheckingState = {
  inviteUserData: {};
  inviteUserPending: boolean;
  inviteUserError: boolean;
};

const inviteUserCheckingInitialState: InviteUserCheckingState = {
  inviteUserData: {},
  inviteUserPending: false,
  inviteUserError: false,
};

export const inviteUserCheckingReducer = createReducer(
  inviteUserCheckingInitialState,
  (builder) => {
    builder
      .addCase(inviteUserCheckingAction.pending, (state) => {
        state.inviteUserPending = true;
      })
      .addCase(inviteUserCheckingAction.fulfilled, (state, { payload }) => {
        state.inviteUserPending = false;
        state.inviteUserData = payload;
      })
      .addCase(inviteUserCheckingAction.rejected, (state) => {
        state.inviteUserPending = false;
        state.inviteUserError = true;
      });
  }
);

export type UpdateUserPasswordState = {
  updateUserPasswordData: {};
  updateUserPasswordPending: boolean;
  updateUserPasswordError: boolean;
  updateUserPasswordSuccess: boolean;
};

const updateUserPasswordInitialState: UpdateUserPasswordState = {
  updateUserPasswordData: {},
  updateUserPasswordPending: false,
  updateUserPasswordError: false,
  updateUserPasswordSuccess: false,
};

export const updateUserPasswordReducer = createReducer(
  updateUserPasswordInitialState,
  (builder) => {
    builder
      .addCase(updateUserPasswordAction.pending, (state) => {
        state.updateUserPasswordPending = true;
      })
      .addCase(updateUserPasswordAction.fulfilled, (state, { payload }) => {
        state.updateUserPasswordPending = false;
        state.updateUserPasswordData = payload;
        state.updateUserPasswordSuccess = true;
      })
      .addCase(updateUserPasswordAction.rejected, (state) => {
        state.updateUserPasswordPending = false;
        state.updateUserPasswordError = true;
      });
  }
);

export type GetIdTokenResultState = {
  getIdTokenResultData: {
    claims: {
      additional_claims: any;
    };
  };
  getIdTokenResultPending: boolean;
  getIdTokenResultError: boolean;
  getIdTokenResultSuccess: boolean;
};

const getTokenResultInitialState: GetIdTokenResultState = {
  getIdTokenResultData: {
    claims: {
      additional_claims: {},
    },
  },
  getIdTokenResultPending: false,
  getIdTokenResultError: false,
  getIdTokenResultSuccess: false,
};

export const getIdTokenResultReducer = createReducer(
  getTokenResultInitialState,
  (builder) => {
    builder
      .addCase(getIdTokenResultAction.pending, (state) => {
        state.getIdTokenResultPending = true;
      })
      .addCase(getIdTokenResultAction.fulfilled, (state, { payload }) => {
        state.getIdTokenResultPending = false;
        state.getIdTokenResultData = payload;
        state.getIdTokenResultSuccess = true;
      })
      .addCase(getIdTokenResultAction.rejected, (state) => {
        state.getIdTokenResultPending = false;
        state.getIdTokenResultError = true;
      });
  }
);

// delete user
export type DeleteUserState = {
  deleteUserData: {};
  getUsersData: IUserWithRole[];
  deleteUserPending: boolean;
  deleteUserError: boolean;
  deleteUserSuccess: boolean;
};

const deleteUserInitialState: DeleteUserState = {
  deleteUserData: {},
  getUsersData: [],
  deleteUserPending: false,
  deleteUserError: false,
  deleteUserSuccess: false,
};

export const deleteUserReducer = createReducer(
  deleteUserInitialState,
  (builder) => {
    builder
      .addCase(deleteUserAction.pending, (state) => {
        state.deleteUserPending = true;
      })
      .addCase(deleteUserAction.fulfilled, (state, { payload }) => {
        state.deleteUserPending = false;
        state.deleteUserData = payload;
        state.deleteUserSuccess = true;
        console.log('payload::', payload);
        // filter out the deleted user
        state.getUsersData = state.getUsersData.filter(
          (user: any) => user.id !== payload.id
        );
      })
      .addCase(deleteUserAction.rejected, (state) => {
        state.deleteUserPending = false;
        state.deleteUserError = true;
      });
  }
);
