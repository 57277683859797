import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAppliedAnalytics,
  fetchUserFailedJobs,
  fetchUserJobs,
} from '../../shared/api/dashboard';
import {
  addApplicantDetails,
  getOverviewData as connectedStatusOverview,
  deleteFetchJobsNamespace,
  disconnectPortal,
  getApplyOverviewData,
  getDiscoveredJobs,
  getJobTitleSuggestions,
  getPortalsInfo,
  getSchedulers,
  getStageOverviewData,
  getUserAnalytics,
  startDiscoveringJobs,
  stepForm,
  uploadResume,
} from '../../shared/api/dataManagement';
import {
  getJobPortals,
  getJobStagesCount,
  startOrStopApplyJobs,
} from '../../shared/api/jobs';
import { IDashboardProps } from '../../shared/models/IJobs';

export const applicantDetailsAction = createAsyncThunk(
  'user/applicantDetails',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await addApplicantDetails(data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDiscoverJobsAction = createAsyncThunk(
  'discover/getDiscoverJobs',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await getDiscoveredJobs(data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const startOrStopApplyingAction = createAsyncThunk(
  'user/startOrStopApplying',
  async (
    {
      action,
      jobPortalId,
      profileId,
    }: { action: string; jobPortalId: number; profileId: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await startOrStopApplyJobs(
        action,
        jobPortalId,
        profileId
      );
      //   await dispatch(getOneProfileByIdAction(null));
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadResumeAction = createAsyncThunk(
  'profile/uploadResume',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await uploadResume(data);
      return response;
    } catch (error: any) {
      console.log('error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const stepFormAction = createAsyncThunk(
  'profile/stepForm',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await stepForm(data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFetchJobNamespaceAction = createAsyncThunk(
  'discover/deleteFetchJobNamespace',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteFetchJobsNamespace(data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const startDiscoveringJobsAction = createAsyncThunk(
  'discover/startDiscoveringJobs',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await startDiscoveringJobs(data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const disconnectPortalAction = createAsyncThunk(
  'jobs/disconnectPortal',
  async (data: any, { getState, rejectWithValue }: any) => {
    try {
      const profileLinkedJobPortals =
        getState().profiles.profilesStore.entities.profiles
          .profileLinkedJobPortals;

      const response = await disconnectPortal(data.profilePortalId);
      return { requestData: data, response, profileLinkedJobPortals };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// reset actions
export const resetUploadResumeStep = createAction(
  'resume/resetUploadResumeStep'
);

export const resetStepForm = createAction('resume/resetStepForm');

export const stepDirectionAction = createAsyncThunk(
  'profile/stepDirection',
  (direction: number) => {
    return direction;
  }
);

export const modifyDiscoveryJobSeenAction = createAsyncThunk(
  'discover/modifyDiscoveryJobSeen',
  (jobId: number) => {
    return jobId;
  }
);

export const getAppliedAnalyticsAction = createAsyncThunk(
  'user/getAppliedAnalytics',
  async (
    {
      period,
      profileId,
    }: {
      period: string;
      profileId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchAppliedAnalytics(period, profileId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFailedOrUserJobsAction = createAsyncThunk(
  'user/getFailedOrUserJobs',
  async (
    {
      page,
      periodQuery,
      profileQuery,
      type,
      filterBy,
    }: {
      page: any;
      periodQuery: string;
      profileQuery: string;
      type: string;
      filterBy: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response =
        type === 'failedJobs'
          ? await fetchUserFailedJobs(
              page,
              { period: periodQuery },
              { profile: profileQuery }
            )
          : await fetchUserJobs(
              filterBy,
              page,
              { period: periodQuery },
              { profile: profileQuery }
            );
      const dashboardProps: IDashboardProps = {
        jobs: response!.items,
        totalCount: response!.total,
        currentPage: response!.page,
        perPage: response!.size,
        pageCount: response!.pages,
      };

      return dashboardProps;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSchedulersAction = createAsyncThunk(
  'user/getSchedulers',
  async (
    {
      profileId,
      jobPortalId,
    }: {
      profileId: number;
      jobPortalId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await getSchedulers(profileId, jobPortalId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPortalsInfoAction = createAsyncThunk(
  'user/getPortalsInfo',
  async (
    {
      profileId,
    }: {
      profileId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await getPortalsInfo(profileId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const setPortalInfoAction = createAsyncThunk(
  'user/getPortalInfo',
  async ({ jobPortalId }: { jobPortalId: number }) => {
    return jobPortalId;
  }
);

export const getPortalsAction = createAsyncThunk(
  'user/getPortals',
  async (_, { rejectWithValue }) => {
    try {
      return await getJobPortals();
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const connectedStatusOverviewAction = createAsyncThunk(
  'user/connectedStatusOverview',
  async (_, { rejectWithValue }) => {
    try {
      return await connectedStatusOverview();
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getApplyOverviewAction = createAsyncThunk(
  'user/getApplyOverview',
  async ({ period }: { period: string }, { rejectWithValue }) => {
    try {
      return await getApplyOverviewData(period);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStageOverviewAction = createAsyncThunk(
  'user/getStageOverview',
  async ({ period }: { period: string }, { rejectWithValue }) => {
    try {
      return await getStageOverviewData(period);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserAnalyticsAction = createAsyncThunk(
  'user/getUserAnalytics',
  async (
    { period, profile }: { period: string; profile: string },
    { rejectWithValue }
  ) => {
    try {
      return await getUserAnalytics(period, profile);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJobTitleSuggestionsAction = createAsyncThunk(
  'user/getJobTitleSuggestions',
  async ({ userString }: { userString: string }, { rejectWithValue }) => {
    try {
      return await getJobTitleSuggestions(userString);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJobStagesCountAction = createAsyncThunk(
  'job/getJobStagesCount',
  async (
    { period, profileId }: { period: string; profileId: string },
    { rejectWithValue }
  ) => {
    try {
      return await getJobStagesCount(profileId, period);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateJobStatusAction = createAsyncThunk(
  'job/updateJobStatus',
  async ({
    actionJob,
    stage,
    stageId,
  }: {
    actionJob: any;
    stage: number;
    stageId: string;
  }) => {
    return { actionJob, stage, stageId };
  }
);

export const sseEventAction = createAsyncThunk(
  'user/sseEventAction',
  async (
    { data, init }: { data: any; init: boolean },
    { getState, rejectWithValue }: any
  ) => {
    try {
      return { data, init };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
