import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { setToken } from '../shared/api/apiService';
import firebase_app from '../shared/config/firebaseClient';
import { AuthContextType } from '../types/authContext';

export const AuthContext: React.Context<AuthContextType> =
  React.createContext<AuthContextType>(null!);

export const useAuthContext = () => React.useContext(AuthContext);

const auth = getAuth(firebase_app);

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const [isActive, setIsActive] = useState(false);

  if (
    process.env.APP_ENV === 'development' ||
    process.env.APP_ENV === 'local'
  ) {
    console.log('App env::', process.env.APP_ENV);
    console.log('App api url::', process.env.API_URL);
    console.log('pathname', router.pathname);
  }

  React.useEffect(() => {
    // const handleVisibilityChange = async () => {
    //   setIsActive(!document.hidden);
    //   if (!document.hidden) {
    //     const token = await auth.currentUser?.getIdToken(true)!;
    //     setToken(token);
    //   }
    //   console.log('handleVisibilityChange', document.hidden);
    // };
    // if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    //   document.addEventListener('visibilitychange', handleVisibilityChange);
    //   if (!document.hidden) {
    //     (async () => {
    //       const token = await auth.currentUser?.getIdToken(true)!;
    //       setToken(token);
    //     })();
    //   }
    //   return () => {
    //     document.removeEventListener(
    //       'visibilitychange',
    //       handleVisibilityChange
    //     );
    //   };
    // }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('user in authContext', user);
        const token = await auth.currentUser?.getIdToken(true)!;

        // setToken(token);
      } else {
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return <AuthContext.Provider value={''}>{children}</AuthContext.Provider>;
};
