import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { EventSourcePolyfill } from 'event-source-polyfill';

// Define the structure of your state
interface EventsState {
  events: Record<string, any>;
  selectedProfileId: string | null;
}

// Define the type of the payload for your actions if necessary
interface EventMessage {
  eventType: string;
  message: any;
}

// Define the initial state using the EventsState interface
const initialState: EventsState = {
  events: {},
  selectedProfileId: null,
};

export const connectEventSource = createAsyncThunk(
  'events/connectEventSource',
  async ({ token, url }: { token: string; url: string }, { dispatch }) => {
    const eventSourceWithPolyfill: any = new EventSourcePolyfill(url, {
      headers: { Authorization: 'Bearer ' + token },
    });

    eventSourceWithPolyfill.onmessage = (msg: MessageEvent) => {
      const messageData: EventMessage = JSON.parse(msg.data);
      dispatch(eventReceived(messageData));
    };

    return eventSourceWithPolyfill;
  }
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    eventReceived: (state, action: PayloadAction<EventMessage>) => {
      const { eventType, message } = action.payload;
      state.events[eventType] = message;
    },
    setSelectedProfileId: (state, action: PayloadAction<string | null>) => {
      state.selectedProfileId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectEventSource.fulfilled, (state, action) => {
      // Handling of the eventSource object
    });
  },
});

export const { eventReceived, setSelectedProfileId } = eventsSlice.actions;
export default eventsSlice.reducer;
