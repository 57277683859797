import { createReducer } from '@reduxjs/toolkit';
import { jobStages } from 'src/utils';
import { IAppliedAnalytics, IDashboardProps } from '../../shared/models/IJobs';
import { scheduleApplyingAction } from '../actions';
import {
  applicantDetailsAction,
  getAppliedAnalyticsAction,
  getFailedOrUserJobsAction,
  getJobStagesCountAction,
  updateJobStatusAction,
} from '../actions/dataManagementAction';

export type ScheduleJobApplying = {
  scheduleJobApplyingData: {};
  scheduleJobApplyingPending: boolean;
  scheduleJobApplyingError: boolean;
  scheduleJobApplyingSuccess: boolean;
  scheduleJobApplyingErrorData: {};
};

const getTokenResultInitialState: ScheduleJobApplying = {
  scheduleJobApplyingData: {},
  scheduleJobApplyingPending: false,
  scheduleJobApplyingError: false,
  scheduleJobApplyingSuccess: false,
  scheduleJobApplyingErrorData: {},
};

export const scheduleApplyingReducer = createReducer(
  getTokenResultInitialState,
  (builder) => {
    builder

      .addCase(scheduleApplyingAction.pending, (state) => {
        state.scheduleJobApplyingPending = true;
      })
      .addCase(scheduleApplyingAction.fulfilled, (state, { payload }) => {
        state.scheduleJobApplyingPending = false;
        state.scheduleJobApplyingData = payload;
        state.scheduleJobApplyingSuccess = true;
      })
      .addCase(scheduleApplyingAction.rejected, (state, { payload }) => {
        state.scheduleJobApplyingPending = false;
        state.scheduleJobApplyingError = true;
        state.scheduleJobApplyingErrorData = payload;
        state.scheduleJobApplyingSuccess = false;
      });
  }
);

export default scheduleApplyingReducer;

export type addApplicant = {
  addApplicantData: {};
  addApplicantPending: boolean;
  addApplicantError: boolean;
  addApplicantSuccess: boolean;
  addApplicantErrorData: {};
};

const addApplicantInitialState: addApplicant = {
  addApplicantData: {},
  addApplicantPending: false,
  addApplicantError: false,
  addApplicantSuccess: false,
  addApplicantErrorData: {},
};

export const addApplicantReducer = createReducer(
  addApplicantInitialState,
  (builder) => {
    builder
      .addCase(applicantDetailsAction.pending, (state) => {
        state.addApplicantPending = true;
      })
      .addCase(applicantDetailsAction.fulfilled, (state, { payload }) => {
        state.addApplicantPending = false;
        state.addApplicantData = payload;
        state.addApplicantSuccess = true;
      })
      .addCase(applicantDetailsAction.rejected, (state, { payload }) => {
        state.addApplicantPending = false;
        state.addApplicantError = true;
        state.addApplicantErrorData = payload;
        state.addApplicantSuccess = false;
      });
  }
);

export type startOrStopApplying = {
  startOrStopApplyingData: {};
  startOrStopApplyingPending: boolean;
  startOrStopApplyingError: boolean;
  startOrStopApplyingSuccess: boolean;
  startOrStopApplyingErrorData: {};
};

const startOrStopApplyingInitialState: startOrStopApplying = {
  startOrStopApplyingData: {},
  startOrStopApplyingPending: false,
  startOrStopApplyingError: false,
  startOrStopApplyingSuccess: false,
  startOrStopApplyingErrorData: {},
};

export const startOrStopApplyingReducer = createReducer(
  startOrStopApplyingInitialState,
  (builder) => {
    builder
      .addCase(applicantDetailsAction.pending, (state) => {
        state.startOrStopApplyingPending = true;
      })
      .addCase(applicantDetailsAction.fulfilled, (state, { payload }) => {
        state.startOrStopApplyingPending = false;
        state.startOrStopApplyingData = payload;
        state.startOrStopApplyingSuccess = true;
      })
      .addCase(applicantDetailsAction.rejected, (state, { payload }) => {
        state.startOrStopApplyingPending = false;
        state.startOrStopApplyingError = true;
        state.startOrStopApplyingErrorData = payload;
        state.startOrStopApplyingSuccess = false;
      });
  }
);

export type appliedAnalytics = {
  appliedAnalyticsData: IAppliedAnalytics;
  appliedAnalyticsPending: boolean;
  appliedAnalyticsError: boolean;
  appliedAnalyticsSuccess: boolean;
  appliedAnalyticsErrorData: {};
};

const appliedAnalyticsState: appliedAnalytics = {
  appliedAnalyticsData: {
    appliedJobs: 0,
    failedJobs: 0,
    failedJobsByPortal: [],
    jobsByStatuses: [],
    countAnalytics: [],
    discoveryJobsList: [],
    appliedJobsAtsScore: [],
  },
  appliedAnalyticsPending: false,
  appliedAnalyticsError: false,
  appliedAnalyticsSuccess: false,
  appliedAnalyticsErrorData: {},
};

export const appliedAnalyticsReducer = createReducer(
  appliedAnalyticsState,
  (builder) => {
    builder
      .addCase(getAppliedAnalyticsAction.pending, (state) => {
        state.appliedAnalyticsPending = true;
      })
      .addCase(getAppliedAnalyticsAction.fulfilled, (state, { payload }) => {
        state.appliedAnalyticsPending = false;
        state.appliedAnalyticsData = payload;
        state.appliedAnalyticsSuccess = true;
      })
      .addCase(getAppliedAnalyticsAction.rejected, (state, { payload }) => {
        state.appliedAnalyticsPending = false;
        state.appliedAnalyticsError = true;
        state.appliedAnalyticsErrorData = payload;
        state.appliedAnalyticsSuccess = false;
      });
  }
);

export type getFailedOrUserJobs = {
  getFailedOrUserJobsData: IDashboardProps;
  getFailedOrUserJobsPending: boolean;
  getFailedOrUserJobsError: boolean;
  getFailedOrUserJobsSuccess: boolean;
  getFailedOrUserJobsErrorData: {};
};

const getFailedOrUserJobsInitialState: getFailedOrUserJobs = {
  getFailedOrUserJobsData: {
    jobs: [],
    totalCount: 0,
    currentPage: 0,
    perPage: 0,
    pageCount: 0,
  },
  getFailedOrUserJobsPending: false,
  getFailedOrUserJobsError: false,
  getFailedOrUserJobsSuccess: false,
  getFailedOrUserJobsErrorData: {},
};

export const getFailedOrUserJobsReducer = createReducer(
  getFailedOrUserJobsInitialState,
  (builder) => {
    builder
      .addCase(getFailedOrUserJobsAction.pending, (state) => {
        state.getFailedOrUserJobsPending = true;
      })
      .addCase(getFailedOrUserJobsAction.fulfilled, (state, { payload }) => {
        state.getFailedOrUserJobsPending = false;
        state.getFailedOrUserJobsData = payload;
        state.getFailedOrUserJobsSuccess = true;
      })
      .addCase(getFailedOrUserJobsAction.rejected, (state, { payload }) => {
        state.getFailedOrUserJobsPending = false;
        state.getFailedOrUserJobsError = true;
        state.getFailedOrUserJobsErrorData = payload;
        state.getFailedOrUserJobsSuccess = false;
      })
      .addCase(updateJobStatusAction.fulfilled, (state, { payload }) => {
        const { actionJob, stage } = payload;
        const updatedJobs = state.getFailedOrUserJobsData.jobs.filter(
          (job: any) => job.id !== actionJob.id
        );

        state.getFailedOrUserJobsData.jobs = updatedJobs;
      });
  }
);

export type getJobStagesCount = {
  getJobStagesCountData: {};
  getJobStagesCountPending: boolean;
  getJobStagesCountError: boolean;
  getJobStagesCountSuccess: boolean;
  getJobStagesCountErrorData: {};
};

const getJobStagesCountInitialState: getJobStagesCount = {
  getJobStagesCountData: {},
  getJobStagesCountPending: false,
  getJobStagesCountError: false,
  getJobStagesCountSuccess: false,
  getJobStagesCountErrorData: {},
};

export const getJobStagesCountReducer = createReducer(
  getJobStagesCountInitialState,
  (builder) => {
    builder
      .addCase(getJobStagesCountAction.pending, (state) => {
        state.getJobStagesCountPending = true;
      })
      .addCase(getJobStagesCountAction.fulfilled, (state, { payload }) => {
        state.getJobStagesCountPending = false;
        state.getJobStagesCountData = payload;
        state.getJobStagesCountSuccess = true;
      })
      .addCase(getJobStagesCountAction.rejected, (state, { payload }) => {
        state.getJobStagesCountPending = false;
        state.getJobStagesCountError = true;
        state.getJobStagesCountErrorData = payload;
        state.getJobStagesCountSuccess = false;
      })
      .addCase(updateJobStatusAction.fulfilled, (state, { payload }) => {
        const { actionJob, stage, stageId } = payload;

        const currentStageId = jobStages[actionJob.combine_status];

        if (state.getJobStagesCountData[stageId] === undefined) {
          state.getJobStagesCountData[stageId] = 0;
        }
        state.getJobStagesCountData[currentStageId] -= 1;
        state.getJobStagesCountData[stageId] += 1;
      });
  }
);
