import '@tailwindcss/aspect-ratio';
import '@tailwindcss/forms';
import '@tailwindcss/typography';

import 'font-awesome/css/font-awesome.min.css';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { AuthContextProvider } from '../context/AuthContext';
import '../styles/globals.css';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import dynamic from 'next/dynamic';
import { Nunito } from 'next/font/google';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { environments } from '../shared/constants';
import * as gtag from '../shared/helpers/gtag';
import { wrapper } from '../store/store';
import initAuth from '../utils/initAuth';

TimeAgo.addDefaultLocale(en);
const nunito = Nunito({ subsets: ['latin'] });

function MyApp({ Component, ...rest }: AppProps) {
  initAuth();
  const { store, props } = wrapper.useWrappedStore(rest);

  if (process.env.APP_ENV == environments.PRODUCTION) {
    const router = useRouter();

    useEffect(() => {
      const handleRouteChange = (url: any) => {
        gtag.pageview(url);
      };
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }, [router.events]);
  }
  const router = useRouter();
  useEffect(() => {
    if (process.env.APP_ENV == environments.PRODUCTION) {
      console.log('NEXT_PUBLIC_GTAG', process.env.NEXT_PUBLIC_GTAG);

      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTAG! });
    }
    const handleRouteChange = (url: any) => {};
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <main className={nunito.className}>
      {process.env.APP_ENV == environments.PRODUCTION ? (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
          />
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${gtag.GA_TRACKING_ID}');
      gtag('config', 'AW-11156782411');
      `,
            }}
          />
          <Script
            src="//code.tidio.co/vgelvurhy25k1qfxufw0bv0uirapcpvd.js"
            async
          />

          <Script id="fb-pixel" strategy="afterInteractive">
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '331346383270025');
              fbq('track', 'PageView');
            `}
          </Script>
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src="https://www.facebook.com/tr?id=331346383270025&ev=PageView&noscript=1"
            />
          </noscript>
        </>
      ) : null}

      <Provider store={store}>
        <AuthContextProvider>
          <Component {...props.pageProps} />
        </AuthContextProvider>
      </Provider>
    </main>
  );
}

const DynamicApp = dynamic(() => Promise.resolve(wrapper.withRedux(MyApp)), {
  ssr: false,
});

export default DynamicApp;
