import { createReducer } from '@reduxjs/toolkit';
import { getRolesAction } from '../actions';

export type RoleState = {
  rolesData: [];
  pending: boolean;
  error: boolean;
};

const initialState: RoleState = {
  rolesData: [],
  pending: false,
  error: false,
};

export const roleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRolesAction.pending, (state) => {
      state.pending = true;
    })
    .addCase(getRolesAction.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.rolesData = payload;
    })
    .addCase(getRolesAction.rejected, (state) => {
      state.pending = false;
      state.error = true;
    });
});

export default roleReducer;
