//TODO:: move values to env variables

import { getApps, initializeApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: 'AIzaSyAooc8BUFVBzn16do6iekgfdlm7tvGTOkQ',
  authDomain: 'thinklabs-d314f.firebaseapp.com', //thinklabs-d314f.firebaseapp.com ,auth.bulkapply.ai
  projectId: 'thinklabs-d314f',
  storageBucket: 'thinklabs-d314f.appspot.com',
  messagingSenderId: '777690378766',
  appId: '1:777690378766:web:a9d0a2d8c168c72875825f',
};
// let firebase_app: FirebaseApp = null!;
// let authUser;
// if (typeof window !== 'undefined' && !getApps().length) {
//   firebase_app = initializeApp(firebaseConfig);
//   const auth = getAuth(firebase_app);
//   (async () => {
//     const persistenceValue: Persistence = {
//       type: 'SESSION',
//     };
//     authUser = await auth.setPersistence(persistenceValue);
//   })();
// }
// export default firebase_app;
// export { authUser };

let firebase_app =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export default firebase_app;
