import { createReducer } from '@reduxjs/toolkit';
import { normalizedData } from '../../shared/helpers/profileNormalizer';
import { IUserProfile } from '../../shared/models/IDataManagement';
import {
  addSearchLinksAction,
  alterProfileAction,
  alterProfileLinkedAction,
  assignedProfilesAction,
  getOneProfileByIdAction,
  getProfileFromRemoteAction,
  getProfilesAction,
  getSingleProfileAction,
  resetProfilesAction,
} from '../actions';
import {
  deleteFetchJobNamespaceAction,
  disconnectPortalAction,
  getJobTitleSuggestionsAction,
  resetStepForm,
  resetUploadResumeStep,
  stepDirectionAction,
  stepFormAction,
  uploadResumeAction,
} from '../actions/dataManagementAction';

export type ProfileState = {
  profilesData: [];
  pending: boolean;
  error: boolean;
  profileDataSuccess: boolean;
  profileData: {} | null;
  profilePending: boolean;
  defaultProfile: {} | null;
  profileFromRemote: {} | null;
  profilesStore: {
    entities: {
      profiles: {
        byId: { [key: string]: IUserProfile };
        allIds: string[];
      };
      sseInfos: {
        byId: { [key: string]: any };
        allIds: string[];
      };
      jobPortals: {
        byId: { [key: string]: any };
        allIds: string[];
      };
      profileLinkedJobPortals: {
        byId: { [key: string]: any };
        allIds: string[];
      };
      searchLinks: {
        byId: { [key: string]: any };
        allIds: string[];
      };
    };
    result: [];
    uniqueIdToIdMapping: { [key: string]: any };
  };
};

const initialState: ProfileState = {
  profilesData: [],
  pending: true,
  error: false,
  profileDataSuccess: false,
  profileData: {},
  profilePending: false,
  defaultProfile: {},
  profileFromRemote: {},
  profilesStore: {
    entities: {
      profiles: {
        byId: {},
        allIds: [],
      },
      sseInfos: {
        byId: {},
        allIds: [],
      },
      jobPortals: {
        byId: {},
        allIds: [],
      },
      profileLinkedJobPortals: {
        byId: {},
        allIds: [],
      },
      searchLinks: {
        byId: {},
        allIds: [],
      },
    },
    result: [],
    uniqueIdToIdMapping: {},
  },
};

export const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProfilesAction.pending, (state) => {
      state.pending = true;
      state.profileDataSuccess = false;
    })
    .addCase(getProfilesAction.fulfilled, (state, { payload }) => {
      const normalizedProfileData = normalizedData(payload.profiles);
      state.profilesData = payload.profiles;
      state.profilesStore = normalizedProfileData;
      state.defaultProfile = {};
      state.profileDataSuccess = true;
      state.pending = false;
    })
    .addCase(getProfilesAction.rejected, (state) => {
      state.pending = false;
      state.error = true;
      state.profileDataSuccess = false;
    })
    .addCase(getOneProfileByIdAction.pending, (state) => {
      state.profilePending = true;
    })
    .addCase(getOneProfileByIdAction.fulfilled, (state, { payload }) => {
      state.profileData = payload;
      state.profilePending = false;
    })
    .addCase(getSingleProfileAction.pending, (state) => {
      state.profilePending = true;
    })
    .addCase(getSingleProfileAction.fulfilled, (state, { payload }) => {
      state.profileData = payload;
      state.profilePending = false;
    })
    .addCase(getProfileFromRemoteAction.pending, (state) => {
      state.profilePending = true;
    })
    .addCase(resetProfilesAction.fulfilled, (state) => {
      state.pending = true;
    })
    .addCase(getProfileFromRemoteAction.fulfilled, (state, { payload }) => {
      console.log('payload in getProfileFromRemoteAction::', payload);
      state.profilesData = Array.isArray(payload)
        ? payload
        : [...state.profilesData, payload];
      state.profileFromRemote = payload;
      state.profilePending = false;
      state.profileDataSuccess = true;
      state.profilesStore = Array.isArray(payload)
        ? normalizedData(payload)
        : state.profilesStore;
      state.error = false;
    })
    .addCase(getProfileFromRemoteAction.rejected, (state, { payload }) => {
      state.profilePending = false;
      state.error = false;
    })
    .addCase(alterProfileAction.fulfilled, (state, { payload }) => {
      const normalizedProfileData = normalizedData(payload?.alteredProfiles);
      state.profilesData = payload?.alteredProfiles;
      state.profilesStore = normalizedProfileData;
      state.defaultProfile = {};
      state.profileData = payload?.alteredProfile;
    })
    .addCase(alterProfileLinkedAction.fulfilled, (state, { payload }) => {
      console.log('payload', payload);
      state.profileData = payload?.updatedProfile;
    });
});

export default profileReducer;

export type AssignedProfileState = {
  assignProfilesData: IUserProfile | IUserProfile[];
  pending: boolean;
  error: boolean;
};

const assignInitialState: AssignedProfileState = {
  assignProfilesData: [],
  pending: false,
  error: false,
};

export const assignedProfileReducer = createReducer(
  assignInitialState,
  (builder) => {
    builder
      .addCase(assignedProfilesAction.pending, (state) => {
        state.pending = true;
      })
      .addCase(assignedProfilesAction.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.assignProfilesData = payload;
      })
      .addCase(assignedProfilesAction.rejected, (state) => {
        state.pending = false;
        state.error = true;
      });
  }
);

export type AddSearchUrlsState = {
  addSearchUrlsData: {};
  addSearchUrlsPending: boolean;
  addSearchUrlsError: boolean;
};

const addSearchUrlsInitialState: AddSearchUrlsState = {
  addSearchUrlsData: {},
  addSearchUrlsPending: false,
  addSearchUrlsError: false,
};

export const addSearchUrlsReducer = createReducer(
  addSearchUrlsInitialState,
  (builder) => {
    builder
      .addCase(addSearchLinksAction.pending, (state) => {
        state.addSearchUrlsPending = true;
      })
      .addCase(addSearchLinksAction.fulfilled, (state, { payload }) => {
        state.addSearchUrlsPending = false;
        state.addSearchUrlsData = payload;
      })
      .addCase(addSearchLinksAction.rejected, (state) => {
        state.addSearchUrlsPending = false;
        state.addSearchUrlsError = true;
      });
  }
);

export type uploadResumeState = {
  uploadResumeData: {};
  uploadResumePending: boolean;
  uploadResumeError: boolean;
  uploadResumeSuccess: boolean;
  uploadResumeErrorData: {};
  suggestedJobTitles: [];
  suggestionsPending: boolean;
  suggestionsError: boolean;
  suggestionsErrorData: {};
};

const uploadResumeInitialState: uploadResumeState = {
  uploadResumeData: {},
  uploadResumePending: false,
  uploadResumeError: false,
  uploadResumeSuccess: false,
  uploadResumeErrorData: {},
  suggestedJobTitles: [],
  suggestionsPending: false,
  suggestionsError: false,
  suggestionsErrorData: {},
};

export const uploadResumeReducer = createReducer(
  uploadResumeInitialState,
  (builder) => {
    builder
      .addCase(uploadResumeAction.pending, (state) => {
        state.uploadResumePending = true;
      })
      .addCase(uploadResumeAction.fulfilled, (state, { payload }) => {
        state.uploadResumePending = false;
        state.uploadResumeData = payload;
        state.uploadResumeSuccess = true;
        state.suggestedJobTitles = payload.data.job_titles;
      })
      .addCase(uploadResumeAction.rejected, (state, { payload }) => {
        state.uploadResumePending = false;
        state.uploadResumeError = true;
        state.uploadResumeErrorData = payload;
        state.uploadResumeSuccess = false;
      })
      .addCase(resetUploadResumeStep, (state) => {
        state.uploadResumePending = false;
        state.uploadResumeData = {};
        state.uploadResumeError = false;
        state.uploadResumeErrorData = {};
        state.uploadResumeSuccess = false;
      })
      .addCase(getJobTitleSuggestionsAction.pending, (state) => {
        state.suggestionsPending = true;
      })
      .addCase(getJobTitleSuggestionsAction.fulfilled, (state, { payload }) => {
        // const mergedArray = [...state.suggestedJobTitles, ...payload];
        // Array.from(new Set(mergedArray))
        state.suggestionsPending = false;
        state.suggestedJobTitles = payload;
        state.suggestionsError = false;
      })
      .addCase(getJobTitleSuggestionsAction.rejected, (state, { payload }) => {
        console.log('error checking ', payload);
        state.suggestionsPending = false;
        state.suggestedJobTitles = state.suggestedJobTitles;
        state.suggestionsError = true;
        state.suggestionsErrorData = payload;
      });
  }
);

export type stepFormDataState = {
  stepFormDataData: {};
  stepFormDataPending: boolean;
  stepFormDataError: boolean;
  stepFormDataSuccess: boolean;
  stepFormDataErrorData: {};
  currentStep: number | null;
};

const stepFormDataInitialState: stepFormDataState = {
  stepFormDataData: {},
  stepFormDataPending: false,
  stepFormDataError: false,
  stepFormDataSuccess: false,
  stepFormDataErrorData: {},
  currentStep: null,
};

export const stepFormDataReducer = createReducer(
  stepFormDataInitialState,
  (builder) => {
    builder
      .addCase(stepFormAction.pending, (state) => {
        state.stepFormDataPending = true;
      })
      .addCase(stepFormAction.fulfilled, (state, { payload }) => {
        state.stepFormDataPending = false;
        state.stepFormDataData = payload;
        state.stepFormDataSuccess = true;
        state.currentStep = payload.step;
      })
      .addCase(stepFormAction.rejected, (state, { payload }) => {
        state.stepFormDataPending = false;
        state.stepFormDataError = true;
        state.stepFormDataErrorData = payload;
        state.stepFormDataSuccess = false;
      })
      .addCase(resetStepForm, (state) => {
        state.stepFormDataPending = false;
        state.stepFormDataError = true;
        state.stepFormDataErrorData = {};
        state.stepFormDataSuccess = false;
        state.stepFormDataData = {};
        state.currentStep = null;
      });
  }
);

export type deleteNamespaceDataState = {
  deleteNamespaceDataData: {};
  deleteNamespaceDataPending: boolean;
  deleteNamespaceDataError: boolean;
  deleteNamespaceDataSuccess: boolean;
  deleteNamespaceDataErrorData: {};
};

const deleteNamespaceDataInitialState: deleteNamespaceDataState = {
  deleteNamespaceDataData: {},
  deleteNamespaceDataPending: false,
  deleteNamespaceDataError: false,
  deleteNamespaceDataSuccess: false,
  deleteNamespaceDataErrorData: {},
};

export const deleteNamespaceDataReducer = createReducer(
  deleteNamespaceDataInitialState,
  (builder) => {
    builder
      .addCase(deleteFetchJobNamespaceAction.pending, (state) => {
        state.deleteNamespaceDataPending = true;
      })
      .addCase(
        deleteFetchJobNamespaceAction.fulfilled,
        (state, { payload }) => {
          state.deleteNamespaceDataPending = false;
          state.deleteNamespaceDataData = payload;
          state.deleteNamespaceDataSuccess = true;
        }
      )
      .addCase(deleteFetchJobNamespaceAction.rejected, (state, { payload }) => {
        state.deleteNamespaceDataPending = false;
        state.deleteNamespaceDataError = true;
        state.deleteNamespaceDataErrorData = payload;
        state.deleteNamespaceDataSuccess = false;
      });
  }
);

export type disConnectWithProfilePortalState = {
  disConnectWithProfilePortalData: {};
  disConnectWithProfilePortalPending: boolean;
  disConnectWithProfilePortalError: boolean;
  disConnectWithProfilePortalSuccess: boolean;
  disConnectWithProfilePortalErrorData: {};
};

const disConnectWithProfilePortalInitialState: disConnectWithProfilePortalState =
  {
    disConnectWithProfilePortalData: {},
    disConnectWithProfilePortalPending: false,
    disConnectWithProfilePortalError: false,
    disConnectWithProfilePortalSuccess: false,
    disConnectWithProfilePortalErrorData: {},
  };

export const disConnectWithProfilePortalReducer = createReducer(
  disConnectWithProfilePortalInitialState,
  (builder) => {
    builder
      .addCase(disconnectPortalAction.pending, (state) => {
        state.disConnectWithProfilePortalPending = true;
      })
      .addCase(disconnectPortalAction.fulfilled, (state, { payload }) => {
        state.disConnectWithProfilePortalPending = false;
        state.disConnectWithProfilePortalData = payload;
        state.disConnectWithProfilePortalSuccess = true;
      })
      .addCase(disconnectPortalAction.rejected, (state, { payload }) => {
        state.disConnectWithProfilePortalPending = false;
        state.disConnectWithProfilePortalError = true;
        state.disConnectWithProfilePortalErrorData = payload;
        state.disConnectWithProfilePortalSuccess = false;
      });
  }
);

export type stepDirectionState = {
  stepDirectionData: null | number;
  stepDirectionPending: boolean;
};

const stepDirectionInitialState: stepDirectionState = {
  stepDirectionData: null,
  stepDirectionPending: false,
};

export const stepDirectionReducer = createReducer(
  stepDirectionInitialState,
  (builder) => {
    builder
      .addCase(stepDirectionAction.pending, (state) => {
        state.stepDirectionPending = true;
      })
      .addCase(stepDirectionAction.fulfilled, (state, { payload }) => {
        state.stepDirectionPending = false;
        state.stepDirectionData = payload;
      });
  }
);

function getCookie(name: string) {
  const cookieArr = document.cookie.split('; ');

  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');

    if (name === cookiePair[0]) {
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if the cookie by that name does not exist
  return null;
}
