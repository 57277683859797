import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPlanInfo, getStripeProductV2 } from '../../shared/api/payment';

export const getPlanInfoAction = createAsyncThunk(
  'payment/getPlanInfo',
  async () => {
    const planInfo = await getPlanInfo();
    return planInfo;
  }
);

export const getStripeV2ProductsAction = createAsyncThunk(
  'payment/getStripeProducts',
  async (_, { rejectWithValue }) => {
    try {
      const stripeProducts = await getStripeProductV2();
      return stripeProducts;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
