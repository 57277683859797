import { NextApiRequest, NextPageContext } from 'next';
import absoluteUrl from 'next-absolute-url';
import { init } from 'next-firebase-auth';

// Define a constant for twelve days in milliseconds
const TWELVE_DAYS_IN_MS = 12 * 60 * 60 * 24 * 1000;
// const isDebugEnabled = process.env.APP_ENV !== environments.PRODUCTION;
const isDebugEnabled = false;
// This function will initialize authentication

const initAuth = (): void => {
  init({
    debug: isDebugEnabled,
    authPageURL: ({ ctx }: { ctx: NextPageContext<any> }) => {
      const isServerSide = typeof window === 'undefined';
      const origin = isServerSide
        ? absoluteUrl(ctx.req as NextApiRequest).origin
        : window.location.origin;
      const destPath = isServerSide ? ctx.resolvedUrl : window.location.href;
      const destURL = new URL(destPath, origin);
      return `/?destination=${encodeURIComponent(destURL.toString())}`;
    },
    appPageURL: ({ ctx }: { ctx: NextPageContext }) => {
      const isServerSide = typeof window === 'undefined';
      console.log('isServerSide', ctx);
      const origin = isServerSide
        ? absoluteUrl(ctx.req as NextApiRequest).origin
        : window.location.origin;
      const params = isServerSide
        ? new URL((ctx.req as NextApiRequest).url as string, origin)
            .searchParams
        : new URLSearchParams(window.location.search);
      const destinationParamVal = params.get('destination')
        ? decodeURIComponent(params.get('destination') as string)
        : undefined;

      let destURL = '/checking';
      if (destinationParamVal) {
        const allowedHosts = [
          'localhost:3000',
          'app.bulkapply.ai',
          'dev-app.bulkapply.ai',
          'auth.bulkapply.ai',
        ];
        const allowed = allowedHosts.includes(
          new URL(destinationParamVal).host
        );
        if (allowed) {
          destURL = destinationParamVal;
        } else {
          console.warn(
            `Redirect destination host must be one of ${allowedHosts.join(
              ', '
            )}.`
          );
        }
      }
      return destURL;
    },
    loginAPIEndpoint: '/api/login',
    logoutAPIEndpoint: '/api/logout',
    onLoginRequestError: (err) => {
      console.error('onLoginRequestError::', err);
    },
    onLogoutRequestError: (err) => {
      console.error('onLoginRequestError::', err);
    },

    // Add the correct type for the configuration object
    firebaseAdminInitConfig: {
      credential: {
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID!,
        clientEmail: process.env.FIREBASE_CLIENT_EMAIL!,
        privateKey: process.env.FIREBASE_PRIVATE_KEY
          ? JSON.parse(process.env.FIREBASE_PRIVATE_KEY)
          : undefined,
      },
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    },
    firebaseClientInitConfig: {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY!,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    },
    cookies: {
      name: process.env.COOKIE_NAME as string,
      keys: [
        process.env.COOKIE_SECRET_CURRENT as string,
        process.env.COOKIE_SECRET_PREVIOUS as string,
      ],
      httpOnly: true,
      maxAge: TWELVE_DAYS_IN_MS,
      overwrite: true,
      path: '/',
      sameSite: 'lax',
      secure: process.env.NEXT_PUBLIC_COOKIE_SECURE === 'true',
      signed: true,
    },
  });
};

export default initAuth;
