import apiRoutes from './apiRoutes';
import apiService, { baseURL } from './apiService';

export const startOrStopApplyJobs = async (
  action: string,
  jobPortalId: number,
  profileId: number
) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.startOrStop}/${action}/${jobPortalId}/${profileId}`,
      data: null,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const getJobPortals = async () => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getJobPortals}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};
export const startOrStopScheduler = async (
  action: string,
  jobPortalId: number,
  profileId: number,
  scheduler: number
) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.startOrStopScheduler}/${action}/${jobPortalId}/${profileId}/${scheduler}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const updateJobStage = async (jobId: number, stageId: number) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.updateJobStage}/${jobId}/${stageId}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const getJobStagesCount = async (profileId: string, period: string) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getJobStagesCount}?profile_id=${profileId}&period=${period}`,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};
