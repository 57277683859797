import { IPage, IPageNation, IStripeProduct } from '../models/IAPIResponse';
import IJob, { IAppliedAnalytics } from '../models/IJobs';
import apiRoutes from './apiRoutes';
import apiService, { baseURL } from './apiService';

export const fetchUserJobs = async (
  filterBy: string,
  page: IPage,
  queryExtra?: any,
  profileInfo?: any
): Promise<IPageNation<IJob[]>> => {
  // let apiResponse =
  try {
    const params = {
      ...page,
      ...queryExtra,
      ...profileInfo,
      filter_by: filterBy,
    };
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getUserJobs}`,
      data: null,
      params,
    });

    const dataToType: IPageNation<IJob[]> = await response.data;
    return dataToType;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const fetchAppliedAnalytics = async (
  period: string,
  profileId: string
): Promise<IAppliedAnalytics> => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getCounterAnalytics}`,
      data: null,
      params: { period, profile_id: profileId },
    });

    const dataToType: IAppliedAnalytics = await response.data;
    return dataToType;
  } catch (error) {
    console.error('Error fetching applied analytics:', error);
    throw error;
  }
};

// get user failed jobs
export const fetchUserFailedJobs = async (
  page: IPage,
  queryExtra?: any,
  profileInfo?: any
): Promise<IPageNation<IJob[]>> => {
  const params = { ...page, ...queryExtra, ...profileInfo };
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getUserFailedJobs}`,
      data: null,
      params,
    });

    const dataToType: IPageNation<IJob[]> = await response.data;
    return dataToType;
  } catch (error) {
    console.error('Error fetching failed jobs:', error);
    throw error;
  }
};

// get stripe products
export const fetchStripeProducts = async (
  productShortName?: string
): Promise<IStripeProduct[] | IStripeProduct> => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getStripeProducts}`,
      data: null,
      params: { product_short_name: productShortName },
    });

    const dataToType: any = await response.data;
    return dataToType;
  } catch (error) {
    console.error('Error fetching stripe products:', error);
    throw error;
  }
};
