import { normalize, schema } from 'normalizr';

// Define your schemas
const searchLinkSchema = new schema.Entity('searchLinks');
const jobPortalSchema = new schema.Entity('jobPortals');

const profileLinkedJobPortalSchema = new schema.Entity(
  'profileLinkedJobPortals',
  {
    job_portal: jobPortalSchema,
    search_link: [searchLinkSchema],
  }
);

const sseInfoSchema = new schema.Entity(
  'sseInfos',
  {},
  {
    // Assuming each profile has a unique sse_info and we want to key by the profile ID
    idAttribute: (value, parent) => parent.id,
  }
);
const profileSchema = new schema.Entity('profiles', {
  sse_info: sseInfoSchema,
  profile_linked_job_portal: [profileLinkedJobPortalSchema],
});

export const normalizedData = (profilesData: any) => {
  const normalizedObj: any = normalize(profilesData, [profileSchema]);

  const uniqueIdToIdMapping: { [key: string]: any } = Object.values(
    normalizedObj.entities.profiles || {}
  ).reduce((acc: { [key: string]: any }, profile: any) => {
    acc[profile.profile_unique_id] = profile.id;
    return acc;
  }, {});
  normalizedObj.uniqueIdToIdMapping = uniqueIdToIdMapping;
  return normalizedObj;
};
