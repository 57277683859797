import axios from 'axios';
import { getCookie, setCookie } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';

export const baseURL = process.env.API_URL;

let context = <GetServerSidePropsContext>{};
let authToken: string | null = null;
export const setContext = (_context: GetServerSidePropsContext) => {
  context = _context;
};

export const setToken = (token: string) => {
  setCookie('BULK_APPLY_AUTH_TOKEN', token);
  authToken = token;
};
let accessToken: string | null = null;

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 100000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      accessToken = getCookie('BULK_APPLY_AUTH_TOKEN') as string;
    } catch (error) {
      console.log('error in interceptor', error);
    }

    if (accessToken !== null) {
      const tokenWithBearer = `Bearer ${accessToken}`;
      config.headers.Authorization = tokenWithBearer;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(
      'Api Status',
      error?.response?.status,
      'isServer::',
      typeof window
    );
    if (error?.response?.status === 401) {
      // check request is server or client
      if (typeof window === 'undefined') {
        const { res } = context;
        console.log('context', context);
        res.writeHead(302, { Location: '/?session=end' });
        res.end();
      } else {
        // const origin = window.location.origin;
        // const destPath = window.location.href;
        // const destURL = new URL(destPath, origin);

        // window.location.href = `/?destination=${encodeURIComponent(
        //   destURL.toString()
        // )}`;
        window.location.href = '/?session=end';
      }
    }
    return Promise.reject(error);
  }
);

const apiService = async ({ method, url, data, params, headers }: any) => {
  return await axiosInstance.request({
    method,
    data,
    url,
    params,
    headers,
    withCredentials: true,
  });
};

export default apiService;
