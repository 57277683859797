import { IUserWithRole } from '../models/IUserManagement';
import apiRoutes from './apiRoutes';
import apiService, { baseURL } from './apiService';

export const getRoles = async () => {
  try {
    const url = `${baseURL}${apiRoutes.getRoles}`;
    const response = await apiService({
      method: 'GET',
      url,
      data: null,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const addUser = async (data: any) => {
  try {
    const url = data.isEdit
      ? `${baseURL}${apiRoutes.addUser}?isEdit=${data.isEdit}&user_id=${data.user_id}`
      : `${baseURL}${apiRoutes.addUser}`;
    const response = await apiService({
      method: 'POST',
      url: url,
      data: data.user,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getUsers}`,
      data: null,
      params: null,
    });
    const dataType: IUserWithRole[] = await response.data;
    return dataType;
  } catch (error) {
    throw error;
  }
};

export const inviteUserChecking = async (unique_id: string) => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.inviteUser}/${unique_id}`,
      data: null,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};
export const deleteUser = async (id: number) => {
  try {
    const response = await apiService({
      method: 'DELETE',
      url: `${baseURL}${apiRoutes.deleteUser}/${id}`,
      data: null,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const updateUserPassword = async (userData: any) => {
  try {
    const response = await apiService({
      method: 'POST',
      url: `${baseURL}${apiRoutes.newPassword}`,
      data: userData,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};
