import apiRoutes from './apiRoutes';
import apiService, { baseURL } from './apiService';

export const getPlanInfo = async () => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getPlanInfo}`,
      data: null,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const cancelSubscription = async () => {
  try {
    // cancelSubscription
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.cancelSubscription}`,
      data: null,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const getStripeProductV2 = async () => {
  try {
    const response = await apiService({
      method: 'GET',
      url: `${baseURL}${apiRoutes.getStripePricesV2}`,
      data: null,
      params: null,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};
