import { createAsyncThunk } from '@reduxjs/toolkit';
import { IdTokenResult } from 'firebase/auth';
import {
  addSearchLinks,
  getUserProfiles,
  scheduleJobApplying,
} from '../../shared/api/dataManagement';
import {
  addUser,
  deleteUser,
  getRoles,
  getUsers,
  inviteUserChecking,
  updateUserPassword,
} from '../../shared/api/userManagement';
import { IUserProfile } from '../../shared/models/IDataManagement';

// get available roles from api
export const getRolesAction = createAsyncThunk('user/roles', async () => {
  const roles = await getRoles();
  return roles;
});

//get profiles based on role
export const getProfilesAction = createAsyncThunk(
  'user/profiles',
  async ({
    id,
    defaultProfileId,
  }: {
    id?: string | null;
    defaultProfileId?: string | null;
  }) => {
    const profiles = await getUserProfiles(id);
    return { profiles, defaultProfileId };
  }
);

export const alterProfileAction = createAsyncThunk(
  'user/alterProfileAction',
  async (
    {
      id,
      alteringData,
      additionalData,
    }: {
      id: string;
      alteringData: any;
      additionalData?: any | null;
    },
    { getState, dispatch }: any
  ) => {
    const profiles = getState().profiles.profilesData;
    const findProfile = profiles.find(
      (profile: IUserProfile) => profile.profile_unique_id === id
    );
    const alteredProfile = { ...findProfile, ...alteringData };
    const alteredProfiles = profiles.map((item: any) => {
      return item.id == findProfile.id ? alteredProfile : item;
    });
    return { alteredProfiles, alteredProfile };
  }
);

export const alterProfileLinkedAction = createAsyncThunk(
  'user/alterProfileLinkedAction',
  async (
    {
      id,
      alteringData,
      additionalData,
      isLinkedProfile,
    }: {
      id: string;
      alteringData: any;
      additionalData?: any | null;
      isLinkedProfile: boolean | null;
    },
    { getState, dispatch }: any
  ) => {
    const profiles = getState().profiles.profilesData;
    let findProfile = profiles.find(
      (profile: IUserProfile) => profile.profile_unique_id === id
    );

    let linkedPortal = [];
    if (isLinkedProfile) {
      const profileMod = { ...findProfile, ...alteringData };

      linkedPortal = profileMod.profile_linked_job_portal.find(
        (item: any) => item.job_portal.id == additionalData.jobPortalId
      );
    } else {
      linkedPortal = findProfile.profile_linked_job_portal.find(
        (item: any) => item.job_portal.id == additionalData.jobPortalId
      );
    }
    const newLinkedData = { ...linkedPortal, ...alteringData };
    const alteredArray = findProfile.profile_linked_job_portal.map(
      (item: any) => {
        return item.id == newLinkedData.id ? newLinkedData : item;
      }
    );

    const profileAltered = { profile_linked_job_portal: alteredArray };
    const updatedProfile = {
      ...findProfile,
      ...profileAltered,
    };

    const alteredProfiles = profiles.map((item: any) => {
      return item.id == findProfile.id ? updatedProfile : item;
    });

    return {
      updatedProfile,
      alteredProfiles,
      linkedProfile: profileAltered,
      singleLinkedData: newLinkedData,
    };
  }
);

export const getOneProfileByIdAction = createAsyncThunk(
  'user/profile',
  async (
    {
      id,
      defaultProfileId,
    }: { id: string | null; defaultProfileId: string | null },
    { getState, dispatch }: any
  ) => {
    await dispatch(getProfilesAction({ defaultProfileId }));
    const profiles = getState().profiles.profilesData;
    const passId = id ? id : profiles[0]?.profile_unique_id;
    let profile: null;
    const findProfile = profiles.find(
      (profile: IUserProfile) => profile.profile_unique_id === passId
    );

    profile = findProfile ? findProfile : null;
    return profile;
  }
);

export const getSingleProfileAction = createAsyncThunk(
  'user/singleProfile',
  async (data: any) => {
    return data;
  }
);
export const resetProfilesAction = createAsyncThunk(
  'user/resetProfiles',
  () => {
    return true;
  }
);
export const getProfileFromRemoteAction = createAsyncThunk(
  'user/profileFromRemote',
  async (id: string) => {
    const profile = await getUserProfiles(id);
    return profile;
  }
);
export const assignedProfilesAction = createAsyncThunk(
  'user/assignedProfiles',
  async (company_user_id?: number) => {
    const profiles = await getUserProfiles(undefined, company_user_id);
    return profiles;
  }
);

export const addUserAction = createAsyncThunk(
  'user/addUser',
  async (data: any, { rejectWithValue }) => {
    try {
      const newUser = await addUser(data);
      return newUser;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// reset user action
export const resetUserAction = createAsyncThunk('user/resetUser', () => {
  return true;
});

export const getUsersAction = createAsyncThunk(
  'user/getUsers',
  async (_, { rejectWithValue }) => {
    try {
      const users = await getUsers();
      return users;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// reset get user action
export const resetGetUsersAction = createAsyncThunk(
  'user/resetGetUsers',
  () => {
    return true;
  }
);

export const addSearchLinksAction = createAsyncThunk(
  'user/addSearchLinks',
  async (
    {
      profileLinkedJobPortalId,
      links,
    }: {
      profileLinkedJobPortalId: number;
      links: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await addSearchLinks(profileLinkedJobPortalId, links);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const inviteUserCheckingAction = createAsyncThunk(
  'user/inviteUser',
  async (uniqueId: string) => {
    const response = await inviteUserChecking(uniqueId);
    return response;
  }
);

export const updateUserPasswordAction = createAsyncThunk(
  'user/updateUserPassword',
  async (userData: any) => {
    const response = await updateUserPassword(userData);
    return response;
  }
);

export const getIdTokenResultAction = createAsyncThunk(
  'user/getIdTokenResult',
  (idTokenResult: IdTokenResult) => {
    return idTokenResult;
  }
);

export const scheduleApplyingAction = createAsyncThunk(
  'user/scheduleApplying',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await scheduleJobApplying(data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// delete user
export const deleteUserAction = createAsyncThunk(
  'user/deleteUser',
  async (id: number) => {
    const response = await deleteUser(id);
    return { id, response };
  }
);
