import { createReducer } from '@reduxjs/toolkit';
import {
  getDiscoverJobsAction,
  modifyDiscoveryJobSeenAction,
  startDiscoveringJobsAction,
} from '../actions/dataManagementAction';

export type getDiscoveryJobs = {
  getDiscoveryJobsData: {
    items: [];
    total: number;
    page: number;
    size: number;
    pages: number;
  };
  getDiscoveryJobsPending: boolean;
  getDiscoveryJobsError: boolean;
  getDiscoveryJobsSuccess: boolean;
  getDiscoveryJobsErrorData: {};
};

const getDiscoveryJobsInitialState: getDiscoveryJobs = {
  getDiscoveryJobsData: {
    items: [],
    total: 0,
    page: 0,
    size: 0,
    pages: 0,
  },
  getDiscoveryJobsPending: false,
  getDiscoveryJobsError: false,
  getDiscoveryJobsSuccess: false,
  getDiscoveryJobsErrorData: {},
};

export const getDiscoveryJobsReducer = createReducer(
  getDiscoveryJobsInitialState,
  (builder) => {
    builder
      .addCase(getDiscoverJobsAction.pending, (state) => {
        state.getDiscoveryJobsPending = true;
      })
      .addCase(getDiscoverJobsAction.fulfilled, (state, { payload }) => {
        state.getDiscoveryJobsPending = false;
        state.getDiscoveryJobsData = payload;
        state.getDiscoveryJobsSuccess = true;
      })
      .addCase(getDiscoverJobsAction.rejected, (state, { payload }) => {
        state.getDiscoveryJobsPending = false;
        state.getDiscoveryJobsError = true;
        state.getDiscoveryJobsErrorData = payload;
        state.getDiscoveryJobsSuccess = false;
      })
      .addCase(modifyDiscoveryJobSeenAction.fulfilled, (state, { payload }) => {
        state.getDiscoveryJobsPending = false;
        state.getDiscoveryJobsData = modifySeen(
          payload,
          state.getDiscoveryJobsData
        );
        state.getDiscoveryJobsSuccess = true;
      });
  }
);

export default getDiscoveryJobsReducer;

function modifySeen(jobId: number, data: any) {
  const item = data.items.find((item: any) => item.id === jobId);
  if (item) {
    item.is_seen = true;
  }
  return data;
}

export type startDiscoveringJobs = {
  startDiscoveringData: {};
  startDiscoveringPending: boolean;
  startDiscoveringError: boolean;
  startDiscoveringSuccess: boolean;
  startDiscoveringErrorData: {};
};

const startDiscoveringInitialState: startDiscoveringJobs = {
  startDiscoveringData: {},
  startDiscoveringPending: false,
  startDiscoveringError: false,
  startDiscoveringSuccess: false,
  startDiscoveringErrorData: {},
};

export const startDiscoveringReducer = createReducer(
  startDiscoveringInitialState,
  (builder) => {
    builder
      .addCase(startDiscoveringJobsAction.pending, (state) => {
        state.startDiscoveringPending = true;
      })
      .addCase(startDiscoveringJobsAction.fulfilled, (state, { payload }) => {
        state.startDiscoveringPending = false;
        state.startDiscoveringData = payload;
        state.startDiscoveringSuccess = true;
      })
      .addCase(startDiscoveringJobsAction.rejected, (state, { payload }) => {
        state.startDiscoveringPending = false;
        state.startDiscoveringError = true;
        state.startDiscoveringErrorData = payload;
        state.startDiscoveringSuccess = false;
      });
  }
);
